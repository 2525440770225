<template>
  <feather-icon 
    :svg-classes="['fill-current h-4 w-4', textColor]" 
    icon="CircleIcon" />
</template>

<script>
export default {
  name: 'CellRendererVerified',
  computed: {
    textColor() {
      return this.params.value ? 'text-primary' : 'text-grey'
    },
  },
}
</script>
